exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-expert-contributors-tsx": () => import("./../../../src/pages/expert-contributors.tsx" /* webpackChunkName: "component---src-pages-expert-contributors-tsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-exclusive-article-tsx": () => import("./../../../src/templates/exclusiveArticle.tsx" /* webpackChunkName: "component---src-templates-exclusive-article-tsx" */),
  "component---src-templates-form-page-tsx": () => import("./../../../src/templates/formPage.tsx" /* webpackChunkName: "component---src-templates-form-page-tsx" */),
  "component---src-templates-modular-page-tsx": () => import("./../../../src/templates/modularPage.tsx" /* webpackChunkName: "component---src-templates-modular-page-tsx" */),
  "component---src-templates-reviews-tsx": () => import("./../../../src/templates/reviews.tsx" /* webpackChunkName: "component---src-templates-reviews-tsx" */),
  "component---src-templates-service-full-tsx": () => import("./../../../src/templates/serviceFull.tsx" /* webpackChunkName: "component---src-templates-service-full-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

